import React, { useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { ThemeContext } from 'src/theme/ThemeProvider'; // replace with the actual path
import { ThemeNameContext } from 'src/theme/ThemeProvider';
const ThemeSwitch = ({ toggleTheme }) => {
  const themeName = useContext(ThemeNameContext);

  return (
    <IconButton onClick={toggleTheme}>
      {themeName === 'NebulaFighterTheme' ? <DarkModeIcon /> : <LightModeIcon />}
    </IconButton>
  );
};

export default ThemeSwitch;
