import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { themeCreator } from './base';
import { StylesProvider } from '@mui/styles';

export const ThemeContext = React.createContext(
  (): void => {}
);
export const ThemeNameContext = React.createContext('');

const ThemeProviderWrapper: React.FC = (props) => {
  const curThemeName = localStorage.getItem('appTheme') || 'NebulaFighterTheme'; /* choose theme here stakecare_dark or NebulaFighterTheme */ 
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);

  const setThemeName = (): void => {
    const newThemeName = themeName === 'stakecare_dark' ? 'NebulaFighterTheme' : 'stakecare_dark';
    localStorage.setItem('appTheme', newThemeName); // Toggle the theme in local storage
    _setThemeName(newThemeName); // Update the state with the new theme
  };

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
      <ThemeNameContext.Provider value={themeName}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeNameContext.Provider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
