import { useContext } from 'react';

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
  Badge
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import MmsTwoToneIcon from '@mui/icons-material/MmsTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import BeachAccessTwoToneIcon from '@mui/icons-material/BeachAccessTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import FilterVintageTwoToneIcon from '@mui/icons-material/FilterVintageTwoTone';
import HowToVoteTwoToneIcon from '@mui/icons-material/HowToVoteTwoTone';
import LocalPharmacyTwoToneIcon from '@mui/icons-material/LocalPharmacyTwoTone';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import TrafficTwoToneIcon from '@mui/icons-material/TrafficTwoTone';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import ChromeReaderModeTwoToneIcon from '@mui/icons-material/ChromeReaderModeTwoTone';
import WorkspacePremiumTwoToneIcon from '@mui/icons-material/WorkspacePremiumTwoTone';
import CameraFrontTwoToneIcon from '@mui/icons-material/CameraFrontTwoTone';
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SimCardAlertIcon from '@mui/icons-material/SimCardAlert';

import AccountTree from '@mui/icons-material/AccountTree';
import SchoolIcon from '@mui/icons-material/School';
import GroupsIcon from '@mui/icons-material/Groups';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';

import LiquorIcon from '@mui/icons-material/Liquor';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import HealingIcon from '@mui/icons-material/Healing';
import YouTubeIcon from '@mui/icons-material/YouTube';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ShareIcon from '@mui/icons-material/Share';
import ChatIcon from '@mui/icons-material/Chat';
import TollIcon from '@mui/icons-material/Toll';

import background from '../../stakecare_gradient_1440.png';



const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main}; 
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};

            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 0)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};

                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);

  return (
    <>
      <MenuWrapper>

            <List component="div">
            <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/welcome"
                  startIcon={<HandshakeIcon />}
                >
                  Welcome
                </Button>
              </ListItem>
              </List>
          </SubMenuWrapper>
        </List>

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Services
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/stakepool"
                  startIcon={<AccountTree />}
                >
                  Stake pool
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button

                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/consulting"
                  startIcon={<SchoolIcon />}
                >
                  Consulting
                </Button>
              </ListItem>
              {/*
              <ListItem component="div">
                <Button

                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/chatbot"
                  startIcon={<ChatIcon />}
                >
                  Chatbot
                </Button>
              </ListItem>
             */}
              <ListItem component="div">
                <Button

                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/ispo"
                  startIcon={<TollIcon />}
                >
                  ISPO
                </Button>
              </ListItem>

            </List>
          </SubMenuWrapper>
        </List>

      <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Partnerships
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/artfulspirits"
                  startIcon={<LiquorIcon />}
                >
                  Artful Spirits
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/friedensdorf"
                  startIcon={<HealingIcon />}
                >
                  Friedensdorf
                </Button>
              </ListItem>






       {/*     <Badge badgeContent="ISPO" color="warning" overlap="circular" anchorOrigin={{
    vertical: 'top',
    horizontal: 'right'
  }}>
*/}

              <ListItem component="div">
          
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/leafai"
                  startIcon={<EnergySavingsLeafIcon />}
                  sx={{display:"flex"}}
                >
                
                  Leaf AI
                  
                </Button>
                 
              </ListItem>

              {/*
            </Badge> */}



            </List>
          </SubMenuWrapper>
        </List>

  <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              About Us
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/about"
                  startIcon={<GroupsIcon />}
                >
                  About Us
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/socialmedia"
                  startIcon={<MmsTwoToneIcon />}
                >
                  Social Media
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

  <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Educational
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/faq"
                  startIcon={<QuestionMarkIcon />}
                >
                  FAQ's
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/videos"
                  startIcon={<YouTubeIcon />}
                >
                  Videos
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
{/*
   <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Legal
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
        
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/faq"
                  startIcon={<SimCardAlertIcon />}
                >
                  Disclaimer
                </Button>
              </ListItem> 

              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/impressum"
                  startIcon={<AssignmentIcon />}
                >
                  Impressum
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
       */}
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
