import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const Welcome = Loader(lazy(() => import('src/content/stakecare/Welcome')));
const News = Loader(lazy(() => import('src/content/stakecare/News')));
// Dashboards

const Tasks = Loader(lazy(() => import('src/content/dashboards/Tasks')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

const Blog = Loader(
  lazy(() => import('src/content/stakecare/Blog'))
);
const Videos = Loader(
  lazy(() => import('src/content/stakecare/Videos'))
);
const Stakepool = Loader(
  lazy(() => import('src/content/stakecare/Stakepool'))
);
const Socialmedia = Loader(
  lazy(() => import('src/content/stakecare/Socialmedia'))
);
const Leafai = Loader(
  lazy(() => import('src/content/stakecare/Leafai'))
);
const Friedensdorf = Loader(
  lazy(() => import('src/content/stakecare/Friedensdorf'))
);
const Faq = Loader(
  lazy(() => import('src/content/stakecare/Faq'))
);
const Consulting = Loader(
  lazy(() => import('src/content/stakecare/Consulting'))
);
const Chatbot = Loader(
  lazy(() => import('src/content/stakecare/Chatbot'))
);
const Ispo = Loader(
  lazy(() => import('src/content/stakecare/Ispo'))
);
const ArtfulSpirits = Loader(
  lazy(() => import('src/content/stakecare/ArtfulSpirits'))
);
const About = Loader(
  lazy(() => import('src/content/stakecare/About'))
);
const Awards = Loader(
  lazy(() => import('src/content/stakecare/Awards'))
);
// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);
const Mandelbrot = Loader(
  lazy(() => import('src/content/stakecare/Mandelbrot'))
);

const PasswordProtector = Loader(
  lazy(() => import('src/content/stakecare/PasswordProtector'))
);

const AiChat = Loader(
  lazy(() => import('src/content/stakecare/AiChat'))
);

const Impressum = Loader(
  lazy(() => import('src/content/stakecare/Impressum'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Welcome />
      },
      {
        path: 'welcome',
        element: <Welcome />
      },
      {
        path: 'news',
        element: <News />
      },
      {
        path: 'impressum',
        element: <Impressum />
      },
      {
        path: 'about',
        element: <About />
      },
      {
        path: 'artfulspirits',
        element: <ArtfulSpirits />
      },
      {
        path: 'consulting',
        element: <Consulting />
      },
      {
        path: 'chatbot',
        element: <Chatbot />
      },
      {
        path: 'ispo',
        element: <Ispo />
      },
      {
        path: 'faq',
        element: <Faq />
      },
      {
        path: 'friedensdorf',
        element: <Friedensdorf />
      },
      {
        path: 'leafai',
        element: <Leafai />
      },
      {
        path: 'socialmedia',
        element: <Socialmedia />
      },
      {
        path: 'blog',
        element: <Blog />
      },
      {
        path: 'stakepool',
        element: <Stakepool />
      },
      {
        path: 'videos',
        element: <Videos />
      },
      {
        path: 'spielerei',
        element: <Mandelbrot />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="tasks" replace />
      },
      {
        path: 'tasks',
        element: <Tasks />
      },
      {
        path: 'messenger',
        element: <Messenger />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'transactions',
        element: <Transactions />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'xmas',
        element: <PasswordProtector correctPassword="FroheWeihnachten" />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'aichat',
        element: <AiChat />
      },
    ]
  }
];

export default routes;
