import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';
import background from '../sidebargradient.png';
import {
  Box,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  Button,
  lighten,
  darken,
  Tooltip
} from '@mui/material';

import GlowingDivider from 'src/components/GlowingDivider'

import SidebarMenu from './SidebarMenu';
import Logo from 'src/components/LogoSign';


const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
    <Box>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background: theme.sidebar.background,
          boxShadow:
            theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
        }}
      >
        <Scrollbar>
          <Box             sx={{backgroundImage:`url(${background})`,
            backgroundPosition: 'center',
            backgroundSize: '220%',
            backgroundRepeat: 'no-repeat',
          }}>
            <Box
              mx={2.5}
              mt={0}
              sx={{
                width: 52,

              }}
            >
              <Logo />
            </Box>
           </Box>
          <SidebarMenu />
        </Scrollbar>

        <Box p={2}>
          <Button
            href="https://cexplorer.io/pool/pool1udr30lxsqa799lyl9haxf0wzg8qsz3xaf2gtpqf4n2s064jrvsj"
            target="_blank"
            rel="noopener noreferrer"
            variant="contained"
            color="success"
            size="small"
            fullWidth
            sx = {{          
            backgroundImage:`url(${background})`,
            backgroundPosition: 'center',
            backgroundSize: '120%',
            backgroundRepeat: 'no-repeat',}}
          >
            Stake with us
          </Button>
        </Box>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            
            background: 
            theme.palette.mode === 'dark'
                ? theme.colors.alpha.white[100]
                : darken(theme.colors.alpha.black[100], 0.5),

          }}
        >
          <Scrollbar>
            <Box mt={0} sx={{}}>
              <Box
                mx={2}
                sx={{
                  width: 52
                }}
              >
                <Logo />
              </Box>
            </Box>
<GlowingDivider/>
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
<GlowingDivider/>
        <Box p={2}>
          <Button
            href="https://cexplorer.io/pool/pool1udr30lxsqa799lyl9haxf0wzg8qsz3xaf2gtpqf4n2s064jrvsj"
            target="_blank"
            rel="noopener noreferrer"
            variant="contained"
            color="success"
            size="small"
            fullWidth
                        sx = {{          
            backgroundImage:`url(${background})`,
            backgroundPosition: 'center',
            backgroundSize: '120%',
            backgroundRepeat: 'no-repeat',}}
          >
            Stake with us
          </Button>
        </Box>

      </Drawer>
      </Box>
    </>
  );
}

export default Sidebar;
