import React from 'react';
import './neon.css';
// background: rgb(58,180,169);
//background: linear-gradient(90deg, rgba(58,180,169,1) 0%, rgba(196,10,143,1) 40%, rgba(253,29,29,1) 77%, rgba(252,176,69,1) 100%); 
function GlowingDivider() {
  return (
    <div className="gradientdivider gradientanimation">
    </div>
  );
}


export default GlowingDivider;